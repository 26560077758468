<template>
  <div class="container mx-auto">
    <div class="max-w-lg mx-auto mt-10 text-center card">
      <div class="card-title">Login With Credetials</div>
      <div class="card-body">
        <div class="form-control">
          <label class="label">
            <span class="label-text">Email or Phone</span>
          </label>
          <input
            type="text"
            placeholder="example@example.com"
            class="input input-primary input-bordered"
          />
        </div>
        <div class="form-control">
          <label class="label">
            <span class="label-text">Password</span>
          </label>
          <input
            type="password"
            placeholder="password"
            class="input input-primary input-bordered"
          />
        </div>
      </div>
      <div class="card-actions">
        <button class="mx-auto btn btn-primary">Login</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>