<template>
  <section>
    <div class="py-3">
      <h1 class="text-3xl font-bold text-center">Our Products</h1>
    </div>
    <div class="flex flex-wrap items-center justify-center">
      <div class="p-4 aspect-auto w-[350px]">
        <div
          class="
            h-full
            overflow-hidden
            transition
            border-2 border-gray-200
            rounded-lg
            glass
            hover:scale-105 hover:shadow-md
          "
        >
          <img
            class="object-cover object-center w-full lg:h-48 md:h-36"
            src="../assets/streak.png"
            alt="blog"
          />
          <div class="p-3">
            <h2 class="mb-1 text-xs font-semibold tracking-widest text-red-500">
              EXAM
            </h2>
            <a
              class="
                mb-3
                text-lg
                font-medium
                text-gray-900
                cursor-pointer
                title-font
                hover:text-blue-500
              "
            >
              Apar's Streak Program
            </a>

            <div
              class="
                flex flex-wrap
                items-center
                justify-between
                py-1
                border-t border-zinc-300
              "
            >
              <div class="text-lg font-bold text-blue-900">5000৳</div>
              <div class="flex gap-2 font-semibold text-blue-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
                  />
                </svg>
                750
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>